import { useState } from 'react';

interface PaymentData {
    amount: string;
    currency: string;
    title: string;
    cancelUrl: string;
    returnUrl: string;
}

interface GeneratePaymentLinkProps {
    baseUrl: string;
    paymentParams: PaymentData;
}

interface usePaypalHook {
    isLoading: boolean;
    error: string | null;
    fetchPaymentLink: (params: GeneratePaymentLinkProps) => Promise<void>;
}

interface PaymentLinkResponse {
    PayPalUrl: string;
    Status: string;
}

export const usePaypal = (): usePaypalHook => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string|null>(null);

    const fetchPaymentLink: usePaypalHook['fetchPaymentLink'] = async({
        baseUrl,
        paymentParams,
    }) => {
        try {
            setIsLoading(true);
            setError(null);

            const { amount, currency, title, cancelUrl, returnUrl } = paymentParams;

            const formdata = new FormData();
            formdata.append('DonationName', title);
            formdata.append('Amount', amount);
            formdata.append('Currency', currency);
            formdata.append('ReturnUrl', returnUrl);
            formdata.append('CancelUrl', cancelUrl);

            // Make an API request to send data and receive payment link
            const response = await fetch(`${baseUrl}/api/PayPalV2`, {
                method: 'POST',
                body: formdata,
            });

            if (!response.ok) {
                throw new Error('Failed to fetch PayPal payment link');
            }

            const responseData: PaymentLinkResponse = await response.json();

            if (responseData.PayPalUrl) {
                window.location.href = responseData.PayPalUrl;
            }
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        }
    };

    return { isLoading, error, fetchPaymentLink };
};
