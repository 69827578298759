/* eslint-disable react/jsx-props-no-spreading */
import * as ReactDOMClient from 'react-dom/client';
import type { DonationWidgetProps } from './libs/types';
import { useCallback, useState, type FormEventHandler } from 'react';
import FrequencyOptions from '3-components/Donations/FrequencyOptions/FrequencyOptions';
import AmountOptions from '3-components/Donations/AmountOptions/AmountOptions';
import FeeCheckbox from '3-components/FeeCheckbox/FeeCheckbox';
import PaymentOptions from '3-components/Donations/PaymentOptions/PaymentOptions';
import { resolveWidgetSettings, useWidgetSettings, type WidgetCMSSettings } from './hooks/useWidgetSettings';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import LoadingIndicator from '3-components/LoadingIndicator/LoadingIndicator';
import InlineMessage from '3-components/InlineMessage/InlineMessage';
import DonationWidgetProvider, { useDonationWidgetProps } from './DonationWidgetProvider';
import { CREDIT_CARD_PROVIDER, DONATION_FREQUENCY, PAYMENT_TYPE } from '3-components/Donations/libs/common';
import queryString from 'query-string';
import { encryptData } from 'utils/encryption';
import { usePaypal } from '3-components/Donations/hooks/usePaypal';
import scrollToElement from 'utils/scrollToElement';

const DonationWidget = (): JSX.Element => {
    const [donationType, setDonationType] = useState('once');
    const [donationAmount, setDonationAmount] = useState('0');
    const [donationFee, setDonationFee] = useState('0');
    const [selectedPayment, setSelectedPayment] = useState('');

    const [isFormValid, setIsFormValid] = useState(true);

    const { baseURL, donationConfig, sslText, donationPageUrl, labels, campaignData } = useDonationWidgetProps();

    const [amountOptions, setAmountOptions] = useState([
        {
            label: '',
            value: '',
            asDefault: false,
        }
    ]);

    const { fetchPaymentLink, isLoading: isLoadingPaypal } = usePaypal();

    const configUrl = donationConfig?.dataUrl ?? '/';

    const { isLoading: isLoadingConfig, isError: isErrorConfig, data: cmsData } = useWidgetSettings({ endpoint: configUrl }, { lang: donationConfig?.locale ? donationConfig.locale : 'en-AU' }, {
        keepPreviousData: true,
        staleTime: 1000 * 60 * 5,
        select: useCallback((result: WidgetCMSSettings) => {
            return resolveWidgetSettings(result);
        }, []),
        onSuccess(data) {
            // set default value for frequency and amount options;
            const defaultFrequency = data.frequencyField.options.find(opt => {
                return opt.asDefault;
            })?.value ?? 'once';

            const defaultAmount = data.amountField.options[defaultFrequency].find(opt => {
                return opt.asDefault;
            })?.value ?? '0';
            // set default donation type
            setDonationType(defaultFrequency);
            // set default donation amount options
            setAmountOptions(data.amountField.options[defaultFrequency]);
            // set on load donation amount
            setDonationAmount(defaultAmount);

            // set default fee amount if enabled by default
            if (data.additionalFeeSettings.defaultChecked) {
                setDonationFee(data.additionalFeeSettings.feeAmount.toString());
            }
        },
    });

    const currency = donationConfig?.currency ?? 'AUD';
    const currencySymbol = donationConfig?.currencySymbol ?? '$';

    /**
     * On Form Submitted
     */
    const onWidgetSubmit: FormEventHandler<HTMLFormElement> = (ev): void => {
        ev.preventDefault();

        if (!isFormValid) {
            // set focus to donation amount field
            const amountBox = document.querySelector('.amount-field-box');
            if (amountBox) {
                scrollToElement(amountBox as HTMLElement);
            }
            return;
        }

        if (donationPageUrl) {
            const totalAmount = parseFloat(donationAmount) + parseFloat(donationFee);
            const payment = encryptData(selectedPayment);
            const campaignGuid = (campaignData?.campaignGuid && campaignData.campaignGuid) ? campaignData.campaignGuid : undefined;

            const donationPage = queryString.stringifyUrl({
                url: donationPageUrl,
                query: {
                    type: donationType,
                    amount: donationAmount,
                    donationFee,
                    currency: donationConfig?.currency ?? 'AUD',
                    payment,
                    step: '2', // set to directly open personal detail page on donation block
                    campaignGuid,
                },
            });

            if (selectedPayment === PAYMENT_TYPE.PAYPAL) {
                // get paypal payment url and redirect user to paypal page
                // if cancelled user will be back to widget page
                // if success user will be redirected to donation page

                fetchPaymentLink({
                    baseUrl: baseURL as string,
                    paymentParams: {
                        title: donationType,
                        amount: totalAmount.toString(),
                        currency: donationConfig?.currency ?? 'AUD',
                        cancelUrl: window.location.href,
                        returnUrl: donationPage,
                    },
                });
            } else {
                window.location.href = donationPage;
            }
        }
    };

    if (isLoadingConfig) {
        return (
            <LoadingIndicator />
        );
    }

    if (isErrorConfig) {
        return (
            <InlineMessage status="error">
                Something went wrong, please contact administrator.
            </InlineMessage>
        );
    }

    const { frequencyField, amountField, additionalFeeSettings: feeSettings } = cmsData;

    /**
     * Donation Frequency Options
     */
    const frequencyOptions = frequencyField.options.map(option => {
        return {
            label: option.label,
            value: option.value,
            checked: option.asDefault,
            disabled: !option.enable,
        };
    });

    const isMonthlyOptionEnabled = !!frequencyField.options.filter(option => {
        return option.value === 'monthly' && option.enable === true;
    }).length;

    const isEnableSmallNudge = cmsData.frequencyField.smallNudgeSettings.enable && isMonthlyOptionEnabled;

    /**
     * Donation Amount Options
     * - Once & Monthly options
     */
    const onceAmounts = amountField.options.once.map(opt => {
        return { ...opt };
    });

    const monthlyAmounts = amountField.options.monthly.map(opt => {
        return { ...opt };
    });

    const minimumAmountSettings = {
        once: {
            value: cmsData.amountField.minimumAmount.once,
            message: labels?.onceMinimumAmountMessage ?? undefined,
        },
        monthly: {
            value: cmsData.amountField.minimumAmount.monthly,
            message: labels?.monthlyMinimumAmountMessage ?? undefined,
        },
    };

    /**
     * Donation Payment Options
     * - separated by once and monthly options
     */
    const availablePayments = {
        once: cmsData.paymentMethods.find(opt => {
            return opt.type === DONATION_FREQUENCY.ONCE;
        })?.payments,
        monthly: cmsData.paymentMethods.find(opt => {
            return opt.type === DONATION_FREQUENCY.MONTHLY;
        })?.payments,
    };

    /**
     * Payment icons that will be shown under donation amount options
     */
    const paymentIconsOnce: string[] = [];
    const paymentIconsMonthly: string[] = [];

    if (cmsData.paymentMethods) {
        availablePayments?.once?.forEach(payment => {
            const isPaypalAvailable = payment.type === PAYMENT_TYPE.PAYPAL && payment.enable;
            const isCreditCardAvailable = payment.enable && payment.type === PAYMENT_TYPE.CREDIT_CARD;
            const isHongkongCreditCardAvailable = payment.enable && payment.provider === CREDIT_CARD_PROVIDER.IPG;

            if (isPaypalAvailable) {
                paymentIconsOnce.push('paypal');
            }

            if (isCreditCardAvailable) {
                paymentIconsOnce.push('mastercard', 'visa', 'amex');
            }

            if (isHongkongCreditCardAvailable) {
                paymentIconsOnce.push('unionpay');
            }
        });

        availablePayments?.monthly?.forEach(payment => {
            const isCreditCardAvailable = payment.enable && payment.type === PAYMENT_TYPE.CREDIT_CARD;
            const isHongkongCreditCardAvailable = payment.enable && payment.provider === CREDIT_CARD_PROVIDER.IPG;

            if (isCreditCardAvailable) {
                paymentIconsMonthly.push('mastercard', 'visa', 'amex');
            }

            if (isHongkongCreditCardAvailable) {
                paymentIconsMonthly.push('unionpay');
            }
        });
    }

    const paymentIcons = {
        once: paymentIconsOnce,
        monthly: paymentIconsMonthly,
    };

    return (
        <div className="donation-widget-box">
            <div className={`donation-widget-loading-wrapper ${!isLoadingPaypal && `is-hidden`}`}>
                <LoadingIndicator />
            </div>

            {(labels?.widgetTitle || labels?.widgetIntro) && (
                <div className="donation-widget-header">
                    <div className="donation-widget-title">{labels?.widgetTitle}</div>
                    <div className="donation-widget-description">{labels?.widgetIntro}</div>
                </div>
            ) }

            <form className="donation-widget-form" onSubmit={onWidgetSubmit}>
                <div className="donation-widget-field-wrapper frequency-field-box">
                    <FrequencyOptions
                        fieldLabel={cmsData.frequencyField.label}
                        fieldName="donation_type"
                        options={frequencyOptions}
                        enableNudge={isEnableSmallNudge}
                        nudgeLabel={cmsData.frequencyField.smallNudgeSettings.label}
                        onChange={(value): void => {
                            setDonationType(value);

                            if (value === DONATION_FREQUENCY.ONCE) {
                                setAmountOptions(onceAmounts);
                            } else {
                                setAmountOptions(monthlyAmounts);
                            }
                        }}
                    />
                </div>
                <div className="donation-widget-field-wrapper amount-field-box">
                    <AmountOptions
                        fieldLabel={amountField.label}
                        fieldName="amount"
                        customAmountLabel={amountField.customLabel}
                        currencyCode={currency}
                        currencySymbol={currencySymbol}
                        options={amountOptions}
                        onChange={(value, isError): void => {
                            if (isError) {
                                setIsFormValid(false);
                                return;
                            }

                            setDonationAmount(value);
                            setIsFormValid(true);
                        }}
                        paymentIconLabel={amountField.paymentIconLabel}
                        paymentIcons={paymentIcons[donationType]}
                        minimumAmount={minimumAmountSettings[donationType]}
                    />
                </div>
                {feeSettings.enable && (
                    <div className="donation-widget-field-wrapper fee-field-box">
                        <FeeCheckbox
                            feeAmount={feeSettings.feeAmount.toString()}
                            label={feeSettings.label}
                            message={feeSettings.message}
                            isChecked={feeSettings.defaultChecked}
                            onChange={(isChecked): void => {
                                const fee = isChecked ? feeSettings.feeAmount.toString() : '0';
                                setDonationFee(fee);
                            }}
                        />
                    </div>
                )}

                <div className="donation-widget-field-wrapper payment-field-box">
                    <PaymentOptions
                        options={availablePayments[donationType]}
                        onSelect={(value): void => {
                            setSelectedPayment(value);
                        }}
                        sslMessage={sslText}
                    />
                </div>
            </form>
        </div>
    );
};

const queryClient = new QueryClient();

interface DonationBlockInitProps {
    el: HTMLDivElement;
    props: DonationWidgetProps;
}

const DonationWidgetInit = ({ el, props }: DonationBlockInitProps): void => {
    const root = ReactDOMClient.createRoot(el);

    root.render(
        <QueryClientProvider client={queryClient}>
            <DonationWidgetProvider {...props}>
                <DonationWidget />
            </DonationWidgetProvider>
        </QueryClientProvider>
    );
};

export default DonationWidgetInit;
