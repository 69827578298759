import SegmentedButtons, { SegmentedButtonItem } from '3-components/SegmentedButtons/SegmentedButtons';
import SegmentedButtonField from '3-components/SegmentedButtons/SegmentedButtonsField/SegmentedButtonField';
import { useEffect, useState } from 'react';
import PaymentIcons, { type PaymentIconNames } from '3-components/Donations/AmountOptions/PaymentIcons';
import { cn } from 'utils/className';

interface AmountOptionsProps {
    fieldLabel: string;
    customAmountLabel: string;
    customAmountFieldName?: string;
    fieldName: string;
    options: {
        label: string;
        value: string;
        asDefault: boolean;
    }[];
    minimumAmount: {
        value: number;
        message?: string;
    };
    onChange?: (value: string, isError: boolean) => void;
    currencyCode?: string;
    currencySymbol?: string;
    paymentIconLabel?: string;
    paymentIcons: PaymentIconNames[];
}

const AmountOptions = (props: AmountOptionsProps): JSX.Element => {
    const { fieldLabel, customAmountLabel, fieldName, options, onChange, paymentIconLabel, currencyCode = 'AUD', currencySymbol = '$', paymentIcons, minimumAmount, customAmountFieldName = 'custom_amount' } = props;

    const { value: minValue, message: minMessage = 'Minimum donation amount is [$]' } = minimumAmount;

    const [isError, setIsError] = useState(false);

    const defaultValue = options.find(option => {
        return option.asDefault;
    })?.value ?? '0';

    const [selectedValue, setSelectedValue] = useState('0');

    const [customValue, setCustomValue] = useState('');

    useEffect(() => {
        setSelectedValue(options.find(opt => {
            return opt.asDefault;
        })?.value ?? '0');
        setCustomValue('');
        setIsError(false);
    }, [options]);

    useEffect(() => {
        const value = customValue ? customValue : selectedValue;

        if (onChange) {
            onChange(value, isError);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue, customValue, isError]);

    return (
        <div className={cn(`donation-amount-wrapper`, isError && `is-error`)}>
            <SegmentedButtonField
                label={fieldLabel}
            >
                <SegmentedButtons>
                    {options.map(option => {
                        const { label, value } = option;

                        return (
                            <SegmentedButtonItem
                                key={`opt-amount-${value}`}
                                label={label}
                                id={`optAmount_${value}`}
                                name={fieldName}
                                value={value}
                                checked={value === selectedValue}
                                onChange={(): void => {
                                    setSelectedValue(value);
                                    setCustomValue('');
                                    setIsError(false);
                                }}
                            />
                        );
                    })}
                </SegmentedButtons>
            </SegmentedButtonField>
            <div className="custom-amount-wrapper">
                <label className="field-label">{customAmountLabel}</label>
                <div className="custom-donation-amount-field">
                    <div className="donation-amount-prefix">
                        <span className="donation-amount-currency-name">{currencyCode}</span>
                        <span className="donation-amount-currency-symbol">{currencySymbol}</span>
                    </div>
                    <input
                        type="text"
                        placeholder="00.00"
                        id="customAmountField"
                        name={customAmountFieldName}
                        value={customValue}
                        onChange={(el): void => {
                            const { target: { value } } = el;
                            const inputValue = value.replace(/([^0-9.])|([.]{2,})|\.([0-9]{3,})|^\./g, '');

                            if (inputValue && inputValue !== '0') {
                                setSelectedValue(inputValue);
                                setCustomValue(inputValue);

                                if (parseFloat(inputValue) < parseFloat(minValue.toFixed(2))) {
                                    setIsError(true);
                                } else {
                                    setIsError(false);
                                }
                            } else {
                                setIsError(false);
                                setSelectedValue(defaultValue);
                                setCustomValue('');
                            }
                        }}
                        inputMode="numeric"
                        maxLength={9}
                    />
                </div>
                {isError && (
                    <div className="custom-amount-error-message">{minMessage.replace('[$]', `${currencySymbol}${minValue}`)}</div>
                )}
            </div>
            {paymentIcons && (
                <div className="amount-footer">
                    <div className="amount-footer-blurb">{paymentIconLabel}</div>
                    <PaymentIcons
                        icons={paymentIcons}
                    />
                </div>
            )}
        </div>
    );
};

export default AmountOptions;
